import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

// porble volour
const Header = (props) => {
    // user is given as a prop.. display user.uid
    // put line at bottom of header
    return (
        <div>
            <div className="navbar bg-[#800080] text-white p-4 flex">
                <div className="flex-1">
                    {/* <a href="#" className="btn btn-ghost normal-case text-3xl">bots.agori.dev</a> */}
                    <Link to="/" className="btn btn-ghost normal-case text-3xl hover:text-gray-300 active:text-gray-500 hover:scale-110 hover:underline">bots.agori.dev</Link>

                    {
                        props.user ? (
                            <div className="text-sm text-gray-300 mt-1 ml-1">UID: {props.user.uid}</div>
                        ) : (
                            null
                        )
                    }
                </div>
                {/* <div className="flex-1">
                    <ul className="flex justify-end space-x-4">
                        <li>
                            <Link to="/" className="btn btn-ghost hover:text-gray-300 active:text-gray-500 hover:scale-110 hover:underline">Home</Link>
                        </li>
                        <li>
                            <Link to="/Dashboard" className="btn btn-ghost hover:text-gray-300 active:text-gray-500 hover:scale-110 hover:underline">Dashboard</Link>
                        </li>

                    </ul>
                </div> */}
            </div>

            <div className="line bg-gray-300 h-1"></div>
        </div>
    );
};

export default Header;