import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button as ReactButton } from 'react-bootstrap';

import Footer from '../components/Footer';
import Header from '../components/Header';

//import { auth, logOut } from '../firebase';

// chakra
import { Box, Heading, Text, Flex, Spacer, Button } from "@chakra-ui/react"
// purple scheme
import '../styles/Home.css';
const Home = () => {
    // Home page.
    const history = useNavigate();

    return (
        <div>
            <Header />
            
            {/* TailwindCSS */}
            <div className="bg-[#800080] text-white">
                <div className="flex justify-center items-center h-screen">
                    <div className="">
                        <h1 className="text-5xl text-center mb-8">The shutdown of bots.agori.dev</h1>
                        <p className="text-xl">We are sorry to announce that we are shutting down bots.agori.dev</p>
                        <p className="text-xl">I am so grateful for the support i have received for this project over the months.</p>
                        <p className="text-xl">I am sorry for any inconvenience this may cause. However, this is needed. I do not get the funding required to keep this project running.</p>
                        <p className="text-xl">Thank you for your support. Maybe bots.agori.dev will return in the future, and hopefully better than ever.</p>
                        <br/>
                        <p className="text-xl">Thank you.</p>
                        <br/>
                        <p className="text-xl">Consider checking out <a href="https://gimmickbots.com/" target="_blank" rel="noreferrer" className="text-blue-500">Gimmick Bots</a> for a similar service.</p>
                        <br/>
                        <p className="text-xl">- GuglioIsStupid</p>

                        <p>For any questions, please contact me at the <a href="https://discord.gg/8vYrjy3" target="_blank" rel="noreferrer" className="text-blue-500">AGORI Discord server</a></p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Home;